import { Modal, Space } from "antd";
import { ReactNode, useState } from "react";
import ButtonUI from "../button/Button";
import CommonIcons from "../../../assets/icons";
import { useTranslation } from "react-i18next";
import ResponseUI from "../../../constants/response";

interface IProps {
  title?: string;
  open?: boolean;
  children?: ReactNode;
  toggle: () => void;
  className?: string;
  width?: number | string;
  type?: "error" | "content";
  onConfirm?: () => void;
  hiddenAction?: boolean;
  verifyDelete?: boolean;
  maskClosable?: boolean;
}

export default function ModalUI(props: IProps) {
  //! state
  const {
    title,
    open,
    children,
    toggle,
    className,
    width,
    type,
    onConfirm,
    hiddenAction,
    verifyDelete,
    maskClosable = false,
    ...res
  } = props;
  const { t } = useTranslation();
  // top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
  //! render
  if (type === "error") {
    return (
      <Modal
        open={open}
        title={
          <p className="font-medium text-xl">
            {title ?? "Bạn có chắc chắn muốn xoá ?"}
          </p>
        }
        onCancel={toggle}
        maskClosable={maskClosable}
        classNames={{
          header: "text-center",
          content: "flex flex-col items-center justify-center gap-3",
          footer: "flex justify-center items-center",
          body: "flex flex-col items-center",
        }}
        centered
        zIndex={2000}
        footer={
          <Space size={"middle"}>
            <ButtonUI
              onClick={toggle}
              type="default"
              className={`${ResponseUI.button} w-24`}
            >
              {t("cancel")}
            </ButtonUI>
            <ButtonUI
              className={`${ResponseUI.button} w-24`}
              onClick={onConfirm}
              disabled={!verifyDelete}
            >
              {t("confirm")}
            </ButtonUI>
          </Space>
        }
        onOk={onConfirm}
      >
        <div>
          <CommonIcons.WarningOutlined
            className="text-red-500 text-2xl"
            style={{ fontSize: "5rem" }}
          />
        </div>
        <div className="mt-3">{children}</div>
      </Modal>
    );
  }
  return (
    <Modal
      title={<p className="font-medium">{title}</p>}
      open={open}
      className={`${className}`}
      width={width ?? 1000}
      onCancel={toggle}
      centered
      zIndex={2000}
      maskClosable={maskClosable}
      footer={
        !hiddenAction && (
          <Space>
            <ButtonUI onClick={toggle} type="primary" ghost danger>
              {t("cancel")}
            </ButtonUI>
            <ButtonUI onClick={onConfirm}>{t("confirm")}</ButtonUI>
          </Space>
        )
      }
      {...res}
    >
      <div>{children}</div>
    </Modal>
  );
}
