import React from "react";

interface IProps {
  title: string | React.ReactNode;
  value: string | number;
  unit?: string;
  actionNode?: React.ReactNode;
}
export default function BoxInfo(props: IProps) {
  const { title, value, unit, actionNode } = props;
  return (
    <div className="flex flex-col gap-3 justify-between rounded-md bg-[#ECF0F7] p-3">
      <div className="flex justify-between">
        <p>{title}</p>
        {actionNode}
      </div>

      <p>
        <span className="font-medium text-base">{value}</span> {unit}
      </p>
    </div>
  );
}
