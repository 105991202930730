import { urlsAPI } from "../../constants/api";
import httpService from "../../services/httpService";

class AuthService {
  login(body: any) {
    return httpService.post(urlsAPI.auth.login, body);
  }
  logout() {
    return httpService.post(urlsAPI.auth.logout, {});
  }
  user() {
    return httpService.get(urlsAPI.auth.user);
  }
  changePassword(body: any) {
    return httpService.post(urlsAPI.auth.changePassword, body);
  }
  saveProfile(body: any) {
    return httpService.post(urlsAPI.auth.saveProfile, body);
  }
  forgotPassword(body: any) {
    return httpService.post(urlsAPI.auth.forgotPassword, body);
  }
  resetPassword(body: any) {
    return httpService.post(urlsAPI.auth.resetPassword, body);
  }
  postActiveNoAuth(body: any) {
    return httpService.post(
      `${urlsAPI.auth.activateNoAuth}?sendActivationMail=true`,
      body
    );
  }

  test() {
    return httpService.get(urlsAPI.test.test);
  }
}

export default new AuthService();
