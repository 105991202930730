import { useParams } from "react-router-dom";
import { CommonStyles } from "../../../components/ui";
import baseRouter from "../../../router/baseRouter";
import CommonIcons from "../../../assets/icons";
import useGetAttributeKey from "../../../modules/telemetry/hooks/useGetAttributeKey";
import { ENTITY_TYPE, SCOPE, TicketStatus } from "../../../constants/Index";
import { parseQueryString } from "../../../helpers";
import TikcetStationTable from "../../../components/tickets/TicketStation";
import ModalTicket from "../../../components/overall/modalTicket";
import useToggle from "../../../hooks/useToggle";
import { useEffect, useState } from "react";
import useGetAttributeScope from "../../../modules/telemetry/hooks/useGetAttributeScope";
import ResponseUI from "../../../constants/response";

export default function TicketStation() {
  //! state
  const parmas = useParams();
  const idStation = parmas.id;
  const {
    open: openAddTicket,
    shouldRender: shouldRenderAddTicket,
    toggle: toggleAddTicket,
  } = useToggle();

  const { data, isLoading, refetch } = useGetAttributeScope(
    ENTITY_TYPE,
    String(idStation),
    SCOPE.SERVER_SCOPE,
    true,
    parseQueryString({
      keys: "hisTicket",
    })
  );
  const [dataFilter, setDataFilter] = useState([]);
  //! function

  useEffect(() => {
    if (!isLoading) {
      setDataFilter(data?.data[0]?.value);
    }
  }, [isLoading]);

  //! render
  const renderExtra = () => {
    return (
      <CommonStyles.ButtonUI
        onClick={toggleAddTicket}
        Icons={<CommonIcons.PlusOutlined />}
        className={`${ResponseUI.button}`}
      >
        Thêm ticket
      </CommonStyles.ButtonUI>
    );
  };
  return (
    <div className="flex flex-col gap-5">
      <CommonStyles.TitleCardUI
        title="Danh sách ticket theo trạm"
        extra={renderExtra()}
        subTitle={[
          {
            title: "Quản lý hệ thống",
            link: baseRouter.tickets,
          },
          {
            title: "Ticket sự cố",
            link: baseRouter.tickets,
          },
          {
            title: "Danh sách ticket theo trạm",
            link: `${baseRouter.tickets}/${idStation}`,
          },
        ]}
      />
      {/* <div className="flex">
        <CommonStyles.SelectUI
          label="Trạng thái"
          placeholder="Trạng thái"
          options={TicketStatus}
          allowClear
          value={filterStatus}
          onChange={(status) => {
            setFilterStatus(status);
          }}
        />
      </div> */}
      <TikcetStationTable
        idStation={String(idStation)}
        data={(dataFilter as any) ?? []}
        isLoading={isLoading}
        refetch={refetch}
      />
      {shouldRenderAddTicket && (
        <CommonStyles.ModalUI
          title="Thêm Ticket"
          toggle={toggleAddTicket}
          open={openAddTicket}
          hiddenAction
        >
          <ModalTicket
            toggle={toggleAddTicket}
            idStation={String(idStation)}
            refetch={refetch}
            onlyCreate={true}
          />
        </CommonStyles.ModalUI>
      )}
    </div>
  );
}
