import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { IDataChart, IDataTime } from "../../../../interface";
import { IDevice } from "../../../../modules/device/device.interface";
import { IDateFilter } from "../../../../interface/IDateFilter";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import {
  PluginTextCenterChart,
  helpCalculateForDemoMonth1112,
  parseQueryString,
} from "../../../../helpers";
import { TelemetryValue } from "../../../../modules/telemetry/telemetry.interface";
import { wsTelemeetry } from "../../../../helpers/ws";
import {
  ColorLineChart,
  ENTITY_TYPE,
  ID_WS,
} from "../../../../constants/Index";
import { useGetLabelsChart } from "../../../../hooks/useGetLabelChart";
import { CommonStyles } from "../../../ui";
import _ from "lodash";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import { colorHighLow } from "../../../../constants/color";
import { segment } from "../../../../helpers/chart";
import ExtraValueRealtime from "../../../ui/ExtraComponent/ExtraValueRealtime";

interface IProps {
  detailDevice: IDevice;
  dateFilter: IDateFilter;
  loading: boolean;
  title: string;
  renderBoxReport: (
    dataChart: IDataTime[],
    keyFilter: string
  ) => React.ReactNode;
}
export default function RealChartWaterLevel(props: IProps) {
  //! state
  const { detailDevice, dateFilter, loading, title, renderBoxReport } = props;
  const [dataChart, setDataChart] = useState<IDataChart>();
  const { labelChart } = useGetLabelsChart(
    dataChart?.dataChart,
    dateFilter,
    loading,
    dataChart?.dataChart && dataChart?.dataChart.length > 0
  );
  const { data: dataCrack } = useGetAttributeKey(
    ENTITY_TYPE,
    detailDevice.id.id,
    true,
    parseQueryString({ keys: "colorChart" })
  );
  let i = 0;
  let valueTemp: any = [];
  // const [keyChart, setKeyChart] = useState(0);

  //! function
  const handleRealtime = (data: any) => {
    if (!data) {
      return;
    }
    const valueRealTime = JSON.parse(data[0][1]);
    const timeReal = data[0][0];
    const convertedDate = moment(timeReal);

    const item = {
      minute: convertedDate.minute(),
      hour: convertedDate.hour(),
      date: convertedDate.date(),
      month: convertedDate.month(),
      data: valueRealTime,
    };
    if (i === 0) {
      let temp: any = [...(dataChart?.dataChart as any)];
      temp.push(item);
      temp.splice(0, 1);
      valueTemp = temp;
      i = 1;
      setDataChart((prev) => ({
        ...prev,
        station: detailDevice.name,
        dataChart: temp,
      }));
    } else {
      let temp: any = [...valueTemp];
      temp.push(item);
      temp.splice(0, 1);
      valueTemp = temp;

      setDataChart((prev) => ({
        ...prev,
        station: detailDevice.name,
        dataChart: temp,
      }));
    }
    // setKeyChart((prev) => prev + 1);
  };
  useEffect(() => {
    const dataDefaultReal = async () => {
      const dataElement: IDataTime[] = [];

      const dataLow_High = telemetryService.getAttributeKey(
        detailDevice?.id?.entityType,
        detailDevice?.id?.id,
        parseQueryString({ keys: "Sethigh" })
      );

      const detailStation = await telemetryService.getTimeSeries(
        detailDevice?.id?.entityType,
        detailDevice?.id?.id,
        parseQueryString({
          startTs: dateFilter.endDate - 3600000 * 3,
          endTs: dateFilter.endDate,
          limit: 50000,
          useStrictDataTypes: true,
          keys: "data",
          orderBy: "ASC",
        })
      );

      detailStation?.data?.data?.forEach(
        (element: { ts: number; value: TelemetryValue }) => {
          const convertedDate = moment(element.ts);
          const minute = convertedDate.minute();
          const hour = convertedDate.hour();
          const date = convertedDate.date();
          const month = convertedDate.month();
          const item = {
            minute,
            hour,
            date,
            month,
            data: element.value,
          };
          dataElement.push(item);
        }
      );

      setDataChart({
        station: detailDevice.name,
        dataChart: dataElement,
        dataLow_High: (await dataLow_High).data,
      });
    };
    if (detailDevice && dateFilter.value == "real") {
      dataDefaultReal();
    }
  }, [detailDevice, dateFilter]);

  useEffect(() => {
    const dataReal = async () => {
      wsTelemeetry(
        "plugins/telemetry",
        detailDevice.id.id,
        detailDevice.id.entityType,
        ID_WS.CrackMetter,
        1000,
        "HISTORY",
        handleRealtime,
        moment.utc(Date.now()).valueOf()
      );
    };

    if (
      detailDevice &&
      dateFilter.value == "real" &&
      dataChart?.dataChart &&
      dataChart?.dataChart?.length > 0
    ) {
      dataReal();
    }
  }, [
    dataChart?.dataChart && dataChart?.dataChart?.length > 0,
    dateFilter.value,
  ]);

  const generateDataChart = useCallback(() => {
    if (!!dataChart?.dataChart && dataChart.dataChart.length > 0) {
      const dataLine = dataChart?.dataChart.map((child) => {
        return child.data["Water Main Level"].status
          ? helpCalculateForDemoMonth1112(
              detailDevice.id.id,
              child.data["Water Main Level"].value
            )
          : NaN;
      });
      return dataLine;
    } else {
      return [{}];
    }
  }, [dataChart, loading, dateFilter]);

  //! render
  const keyFilter = "Water Main Level";
  return (
    <div className="flex flex-col gap-4">
      <CommonStyles.CardResize
        title={title}
        className=" border border-gray-200"
        extra={
          <ExtraValueRealtime
            lastestValueRealtime={
              dataChart?.dataChart && dataChart.dataChart.slice(-1)[0]
            }
            helpCalculateForDemoMonth1112={helpCalculateForDemoMonth1112}
            typeValue="Water Main Level"
            detailDevice={detailDevice}
          />
        }
      >
        <CommonStyles.LineChart
          labels={labelChart ?? []}
          dataChart={[
            {
              borderColor: dataCrack?.data?.find(
                (el) => el.key === "colorChart"
              )?.value,
              data: generateDataChart(),
              cubicInterpolationMode: "monotone",
              lineTension: 3,
              borderWidth: 2.5,
              segment: segment,
              spanGaps: true,
              tension: 0.5,
              yAxisID: "y",
            },
            // {
            //   label: `Ngưỡng cao ${dataChart?.station?.split("/")[1]}`,
            //   data: Array.from({ length: generateDataChart()?.length }, () =>
            //     Number(
            //       dataChart?.dataLow_High &&
            //         dataChart?.dataLow_High[0]?.value?.split(",")[1]
            //     )
            //   ),
            //   borderColor: colorHighLow.value,
            //   lineTension: 0,
            //   borderWidth: 1.5,
            //   borderDash: [5, 5],
            //   order: 0,
            //   yAxisID: "y1",
            // },
          ]}
          displayLengend={false}
        />
      </CommonStyles.CardResize>
      {renderBoxReport(dataChart?.dataChart as IDataTime[], keyFilter)}
    </div>
  );
}
