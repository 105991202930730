import CDRCTickets from "../../components/tickets/CDRC";
import LMMNTickets from "../../components/tickets/LMMN";
import { CommonStyles } from "../../components/ui";
import baseRouter from "../../router/baseRouter";

export default function Tickets() {
  //! state
  //! function
  //! render
  return (
    <div className="flex flex-col gap-5">
      <CommonStyles.TitleCardUI
        title="Danh sách ticket sự cố"
        subTitle={[
          {
            title: "Quản lý hệ thống",
            link: baseRouter.tickets,
          },
          {
            title: "Ticket sự cố",
            link: baseRouter.tickets,
          },
        ]}
      />

      {/* <CommonStyles.CardResize title="Cấu hình căng dây và rung chấn">
        <CDRCTickets />
      </CommonStyles.CardResize> */}

      <CommonStyles.CardResize title="Cấu hình lượng mưa và mực nước">
        <LMMNTickets />
      </CommonStyles.CardResize>
    </div>
  );
}
