import { useEffect, useState } from "react";
import { IDataTime } from "../../../../../interface";
import moment from "moment";
import telemetryService from "../../../../../modules/telemetry/telemetry.service";
import { IDevice } from "../../../../../modules/device/device.interface";
import {
  functionRole,
  helpCalculateForDemoMonth1112,
  parseQueryString,
} from "../../../../../helpers";
import { ITelemetry } from "../../../../../modules/telemetry/telemetry.interface";
import { CommonStyles } from "../../../../ui";
import CommonIcons from "../../../../../assets/icons";
import { getDataFilterDate } from "../../../../../helpers/filterDate";
import { LMMN } from "../../../../../constants/Index";
interface IProps {
  detailHistory: IDataTime | undefined;
  detailDevice: IDevice;
  checkSendSMS: boolean;
  toggle: () => void;
  setSms: any;
}
export default function ModalDetailHistory(props: IProps) {
  //! state
  const { detailHistory, detailDevice, checkSendSMS, toggle, setSms } = props;
  const [dataTable, setDataTable] = useState<ITelemetry>();

  //! function
  useEffect(() => {
    const timeHours = async () => {
      const startTimeOfHour = moment(detailHistory?.timeSeries)
        .startOf("hour")
        .valueOf();
      const endTimeOfHour = moment(detailHistory?.timeSeries)
        .endOf("hour")
        .valueOf();
      const detailStation = await telemetryService.getTimeSeries(
        detailDevice?.id?.entityType,
        detailDevice?.id?.id,
        parseQueryString({
          startTs: startTimeOfHour,
          endTs: endTimeOfHour,
          useStrictDataTypes: true,
          limit: 50000,
          keys: "data",
          orderBy: "ASC",
        })
      );
      setDataTable(detailStation.data);
    };
    timeHours();
  }, []);

  const columns = [
    {
      title: "Thời gian",
      dataIndex: "time",
      key: "time",
      render: (_: any, record: any) => {
        const timeHour = moment(record.ts).format("HH:mm");
        return <p>{timeHour}</p>;
      },
    },
    {
      title: "Lượng mưa tích luỹ /h(mm/h)",
      dataIndex: "Hourly rainfall",
      key: "Hourly rainfall",
      render: (_: any, record: any) => {
        return (
          <p>
            {record?.value["Hourly rainfall"]?.status
              ? record?.value["Hourly rainfall"]?.value
              : "-"}
          </p>
        );
      },
    },
    {
      title: "Tổng lượng mưa từ 0h (mm)",
      dataIndex: "Rainfall on the day",
      key: "Rainfall on the day",
      render: (_: any, record: any) => {
        return (
          <p>
            {record?.value["Rainfall on the day"]?.status
              ? record?.value["Rainfall on the day"]?.value
              : "-"}
          </p>
        );
      },
    },
    {
      title: "Mực nước(cm)",
      dataIndex: "waterLevel",
      key: "waterLevel",
      render: (_: any, record: any) => {
        const valueUsing = helpCalculateForDemoMonth1112(
          detailDevice.id.id,
          record?.value["Water Main Level"]?.value
        );
        return (
          <p>{record?.value["Water Main Level"]?.status ? valueUsing : "-"}</p>
        );
      },
    },
    // {
    //   title: "Gửi SMS",
    //   dataIndex: "address",
    //   hidden: checkSendSMS,
    //   key: "sms",
    //   render: (_: any, record: any) => {
    //     const sms: IDataTime = getDataFilterDate(record.ts, record.value);
    //     return (
    //       <CommonStyles.TooltipUI title="Gửi SMS">
    //         <CommonStyles.ButtonUI
    //           ghost
    //           className="flex items-center justify-center"
    //           onClick={() => {
    //             toggle();
    //             setSms(sms);
    //           }}
    //         >
    //           <CommonIcons.SendOutlined className="-rotate-45 -translate-y-0.5" />
    //         </CommonStyles.ButtonUI>
    //       </CommonStyles.TooltipUI>
    //     );
    //   },
    // },
  ];
  const columnsCDRC = [
    {
      title: "Thời gian",
      dataIndex: "time",
      key: "time",
      render: (_: any, record: any) => {
        const timeHour = moment(record.ts).format("HH:mm");
        return <p>{timeHour}</p>;
      },
    },
    {
      title: "Căng kế (cm)",
      dataIndex: "CrackMetter",
      key: "CrackMetter",
      render: (_: any, record: any) => {
        return (
          <p>
            {record?.value["CrackMetter"]?.status
              ? record?.value["CrackMetter"]?.value
              : "-"}
          </p>
        );
      },
    },
    {
      title: "Gia tốc rung chấn (gal)",
      dataIndex: "Vector Gal_Max",
      key: "Vector Gal_Max",
      render: (_: any, record: any) => {
        return (
          <p>
            {record?.value["Vector Gal_Max"]?.status
              ? record?.value["Vector Gal_Max"]?.value
              : "-"}
          </p>
        );
      },
    },
    {
      title: "Cường độ rung chấn(level)",
      dataIndex: "IntensityMax",
      key: "IntensityMax",
      render: (_: any, record: any) => {
        return (
          <p>
            {record?.value["Intensity_Max"]?.status
              ? record?.value["Intensity_Max"]?.value
              : "-"}
          </p>
        );
      },
    },
    // {
    //   title: "Gửi SMS",
    //   dataIndex: "address",
    //   hidden: checkSendSMS,
    //   key: "sms",
    //   render: (_: any, record: any) => {
    //     const sms: IDataTime = getDataFilterDate(record.ts, record.value);
    //     return (
    //       <CommonStyles.TooltipUI title="Gửi SMS">
    //         <CommonStyles.ButtonUI
    //           ghost
    //           className="flex items-center justify-center"
    //           onClick={() => {
    //             toggle();
    //             setSms(sms);
    //           }}
    //         >
    //           <CommonIcons.SendOutlined className="-rotate-45 -translate-y-0.5" />
    //         </CommonStyles.ButtonUI>
    //       </CommonStyles.TooltipUI>
    //     );
    //   },
    // },
  ];
  //! render
  return (
    <CommonStyles.TableUI
      dataSource={dataTable?.data}
      columns={detailDevice?.type === LMMN ? columns : columnsCDRC ?? []}
      scroll={{
        y: 700,
        x: 1000,
      }}
      hasPagination
    />
  );
}
