import httpService from "../services/httpService";
const apiSocket = process.env.REACT_APP_API_WS;
const typeSocket = process.env.REACT_APP_WEBSOCKET;
export const wsTelemeetry = (
  url: string,
  entityId: string, // 31da2f60-bed8-11ee-9c13-f963bb896e46
  entityType: string, // DEVICE
  cmdId: number,
  interval: number,
  scope: string,
  funcHandle?: (e: any) => void,
  startTs?: number, // moment.utc(Date.now()).subtract(45, "minutes").valueOf()
  close?: any
) => {
  const { accessToken: token } = httpService.getToken();
  const webSocket = new WebSocket(
    `${typeSocket}:${apiSocket}/ws/${url}?token=${token}`
  );
  webSocket.onopen = function () {
    const subscriptionCommand = {
      tsSubCmds: [
        {
          entityType: entityType,
          entityId: entityId,
          scope: scope,
          cmdId: cmdId,
          agg: "NONE",
          interval: interval,
          startTs: startTs,
          //   keys: ["data"],
        },
      ],
    };

    const data = JSON.stringify(subscriptionCommand);
    webSocket.send(data);
  };

  webSocket.onmessage = function (event) {
    const received_msg = event.data;
    if (received_msg) {
      const data = JSON.parse(received_msg);
      funcHandle && funcHandle(data?.data?.data);
    }
  };
};

export const wsNotification = (
  url: string,
  subCmd: any,
  funcHandle?: (e: any) => void
) => {
  const { accessToken: token } = httpService.getToken();
  const webSocket = new WebSocket(
    `${typeSocket}:${apiSocket}/ws/${url}?token=${token}`
  );

  const closeWebSocket = () => {
    if (webSocket.readyState === WebSocket.OPEN) {
      webSocket.close();
    }
  };

  webSocket.onopen = function () {
    const subscriptionCommand = subCmd;

    const data = JSON.stringify(subscriptionCommand);
    webSocket.send(data);
  };
  webSocket.onmessage = function (event) {
    const received_msg = event.data;
    if (received_msg) {
      const data = JSON.parse(received_msg);
      funcHandle && funcHandle(data);
    }
  };
};

export const Ws = (url: string) => {
  const { accessToken: token } = httpService.getToken();
  return new WebSocket(`${typeSocket}:${apiSocket}/ws/${url}?token=${token}`);
};
