import { Form } from "antd";
import { CommonStyles } from "../../components/ui";
import useAuth from "../../modules/auth/hooks/useAuth";
import Regex from "../../constants/regex";

export default function FormPassword() {
  const { handleChangePassword } = useAuth();

  const [form] = Form.useForm();
  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  return (
    <Form
      form={form}
      name="changePassword"
      layout="vertical"
      onFinish={handleChangePassword}
      initialValues={initialValues}
      //   onKeyUp={handleKeyUp}
      className="flex justify-start "
    >
      <div className="w-1/3">
        <CommonStyles.FormFieldUI
          name="currentPassword"
          label={"Mật khẩu hiện tại"}
          //   hasFeedback
          rules={[
            {
              required: true,
              message: "Mật khẩu hiện tại không được trống!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              type="password"
              className="h-9 border !border-s-line active:!border-main-color"
              placeholder={"Nhập mật khẩu hiện tại ..."}
            />
          }
        />
        <CommonStyles.FormFieldUI
          name="newPassword"
          label={"Mật khẩu mới"}
          rules={[
            {
              required: true,
              message: "Mật khẩu mới không được trống!",
            },
            {
              pattern: Regex.password,
              message: "Mật khẩu không hợp lệ!",
            },
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              type="password"
              className="h-9 border !border-s-line active:!border-main-color"
              placeholder={"Nhập mật khẩu mới ..."}
              subText="Sử dụng 8 ký tự trở lên kết hợp chữ cái, số"
            />
          }
        />

        <CommonStyles.FormFieldUI
          name="confirmPassword"
          label={"Xác nhận lại mật khẩu"}
          dependencies={["newPassword"]}
          rules={[
            {
              required: true,
              message: "Mật khẩu xác nhận không được trống!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Không khớp với mật khẩu mới!")
                );
              },
            }),
          ]}
          component={
            <CommonStyles.InputUI
              maxLength={50}
              type="password"
              className="h-9 border !border-s-line active:!border-main-color"
              placeholder={"Xác nhận mật khẩu ..."}
            />
          }
        />
        <div className="w-full flex justify-end items-center">
          <CommonStyles.ButtonUI size="large" action="submit">
            Lưu thay đổi
          </CommonStyles.ButtonUI>
        </div>
      </div>
    </Form>
  );
}
