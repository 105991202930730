import { Menu } from "antd";
import type { MenuProps } from "antd";

import { useNavigate } from "react-router-dom";
import {
  Roles,
  menuConfig,
  menuCustomer,
  menuSettings,
} from "../../constants/Index";
import { useAppSelector } from "../../hooks/hooks";
import { useLocation } from "react-router-dom";

export default function SiderMenu(props: any) {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const location = useLocation();
  const onClick: MenuProps["onClick"] = (e: any) => {
    navigate("/" + e?.key);
  };

  let menuUse: any = [];

  switch (user?.lastName) {
    case Roles.ADMIN:
      menuUse = menuSettings;
      break;
    case Roles.CONFIG:
      menuUse = menuConfig;
      break;
    case Roles.CUSTOMER:
      menuUse = menuCustomer;
      break;

    default:
      menuUse = menuCustomer;

      break;
  }
  // if (user?.lastName === Roles.ADMIN) {
  //   menuUse = menuSettings;
  // }
  // if (user?.lastName === Roles.CONFIG) {
  //   menuUse = menuConfig;
  // }
  // if (user?.lastName === Roles.CUSTOMER) {
  //   menuUse = menuCustomer;
  // }
  return (
    <Menu
      className="!bg-main-color !text-white !border-none"
      mode="inline"
      defaultSelectedKeys={[`${location.pathname.substring(1)}`]}
      defaultOpenKeys={[`${location.pathname.substring(1)}`]}
      onClick={(e) => onClick(e)}
      items={menuUse}
      theme="light"
      getPopupContainer={(node) => node.parentNode as HTMLElement}
    />
  );
}
