import { ChangeEvent, useEffect, useState } from "react";
import { CommonStyles } from "../../../ui";
import Regex from "../../../../constants/regex";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import { ENTITY_TYPE, SCOPE } from "../../../../constants/Index";
import { useAppDispatch } from "../../../../hooks/hooks";
import { getErrorMsg, parseQueryString, showMsg } from "../../../../helpers";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";

interface IProps {
  idStation: string;
}

const TimeAlarm = (props: IProps) => {
  //! state
  const dispatch = useAppDispatch();
  const { idStation } = props;
  const [time, setTime] = useState<string | number>(10);
  const { data, isLoading } = useGetAttributeKey(
    ENTITY_TYPE,
    idStation,
    true,
    parseQueryString({ keys: "TimeAlarm" })
  );
  //! function
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTime(event.target.value);
    // if (Regex.number.test(event.target.value)) {
    //   setTime(Number(event.target.value));
    // } else {
    //   setTime(10);
    // }
  };

  const handleCheck = async () => {
    if (!Regex.number.test(String(time))) {
      dispatch(showMsg("error", "Giá trị không hợp lệ!"));
      return;
    }

    if (data?.data && Number(data.data[0]?.value) == time) {
      return;
    }
    try {
      const postSettime = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SHARED_SCOPE,
        {
          TimeAlarm: time,
        }
      );
      if (postSettime.status === 200) {
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };

  //! effect
  useEffect(() => {
    if (data?.data) {
      setTime(Number(data.data[0]?.value ?? 10));
    }
  }, [isLoading]);
  //! render
  return (
    <CommonStyles.SubCard
      title="Thời gian chuông báo động(s)"
      className="justify-between pb-9"
    >
      <CommonStyles.InputUI
        value={time}
        onChange={handleChange}
        className=" border-0"
        addonAfter={
          <button className="cursor-pointer" onClick={handleCheck}>
            Xác nhận
          </button>
        }
      />
    </CommonStyles.SubCard>
  );
};

export default TimeAlarm;
