import { Form } from "antd";
import React, { useState } from "react";
import { CommonStyles } from "../../../../components/ui";
import ResponseUI from "../../../../constants/response";
import Regex from "../../../../constants/regex";
import authService from "../../../../modules/auth/auth.service";
import { useAppDispatch } from "../../../../hooks/hooks";
import { getErrorMsg, showMsg } from "../../../../helpers";

interface IProps {
  toggle: () => void;
  email: string | null;
}
export default function ModalResetPassword(props: IProps) {
  //! state
  const { toggle, email } = props;
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const initialValues = {
    password: "",
    confirm_password: "",
  };
  const [loading, setLoading] = useState(false);
  //! function
  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const result = await authService.forgotPassword({ email });

      if (result.status === 200) {
        const resultReset = await authService.resetPassword({
          resetToken: result.data,
          password: values.password,
        });
        if (resultReset.status === 200) {
          dispatch(showMsg("success", "Thành công"));
          toggle();
        }
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    } finally {
      setLoading(false);
    }
  };
  //! render
  return (
    <Form
      name="resetPassw"
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={handleSubmit}
    >
      <CommonStyles.FormFieldUI
        name="password"
        required
        label="Mật khẩu"
        rules={[
          {
            required: true,
            message: "Mật khẩu mới không được trống!",
          },
          {
            pattern: Regex.password,
            message: "Mật khẩu không hợp lệ!",
          },
        ]}
        component={
          <CommonStyles.InputUI
            maxLength={50}
            disabled={loading}
            type="password"
            className="h-9 border !border-s-line active:!border-main-color"
            placeholder={"Nhập mật khẩu mới ..."}
            subText="Sử dụng 8 ký tự trở lên kết hợp chữ cái, số"
          />
        }
      />

      <CommonStyles.FormFieldUI
        name="confirm_password"
        required
        label="Nhập lại mật khẩu"
        dependencies={["confirm_password"]}
        rules={[
          {
            required: true,
            message: "Mật khẩu xác nhận không được trống!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Không khớp với mật khẩu mới!"));
            },
          }),
        ]}
        component={
          <CommonStyles.InputUI
            maxLength={50}
            type="password"
            disabled={loading}
            className="h-9 border !border-s-line active:!border-main-color"
            placeholder={"Xác nhận mật khẩu ..."}
          />
        }
      />
      <div className="flex justify-center items-center gap-5">
        <CommonStyles.ButtonUI
          ghost
          danger
          className={ResponseUI.button + ` w-24`}
          onClick={toggle}
          loading={loading}
        >
          Hủy
        </CommonStyles.ButtonUI>
        <CommonStyles.ButtonUI
          loading={loading}
          action="submit"
          className={ResponseUI.button}
        >
          Xác nhận
        </CommonStyles.ButtonUI>
      </div>
    </Form>
  );
}
