import React, { useCallback, useEffect } from "react";
import { CommonStyles } from "../../../ui";
import { Form } from "antd";
import { useAppDispatch } from "../../../../hooks/hooks";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import { ENTITY_TYPE, SCOPE } from "../../../../constants/Index";
import {
  getErrorMsg,
  getStatusAction,
  parseQueryString,
  showMsg,
} from "../../../../helpers";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import Regex from "../../../../constants/regex";
interface IProps {
  idStation: string;
}
export default function LMMNDevice(props: IProps) {
  //! state
  const { idStation } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { data, isLoading, refetch } = useGetAttributeKey(
    ENTITY_TYPE,
    idStation,
    true,
    parseQueryString({ keys: "Sethigh" })
  );

  const hight = data?.data.find((item) => item.key === "Sethigh");

  const setHigh = hight?.value.split(",");
  const initialValues = {
    instantaneousRainfallHigh: (setHigh && Number(setHigh[4])) ?? 0,
    hourlyRainfallHigh: (setHigh && Number(setHigh[7])) ?? 0,

    rainfallOnTheDayHigh: (setHigh && Number(setHigh[3])) ?? 0,

    totalRainfallHigh: (setHigh && Number(setHigh[6])) ?? 0,

    waterMainLevelHigh: (setHigh && Number(setHigh[1])) ?? 0,
  };

  const valueInit = [
    (setHigh && Number(setHigh[4])) ?? 0,
    (setHigh && Number(setHigh[7])) ?? 0,
    (setHigh && Number(setHigh[3])) ?? 0,
    (setHigh && Number(setHigh[6])) ?? 0,
    (setHigh && Number(setHigh[1])) ?? 0,
  ];
  const valueInstantaneousRainfallHigh = Form.useWatch(
    "instantaneousRainfallHigh",
    form
  );
  const valueThourlyRainfallHigh = Form.useWatch("hourlyRainfallHigh", form);
  const valueRainfallOnTheDayHigh = Form.useWatch("rainfallOnTheDayHigh", form);
  const valueTotalRainfallHigh = Form.useWatch("totalRainfallHigh", form);
  const valueWaterMainLevelHigh = Form.useWatch("waterMainLevelHigh", form);
  //! function
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [isLoading]);

  const handleSubmit = async () => {
    const values = form.getFieldsValue();
    const valid = form.getFieldsError();
    let check = true;
    valid.forEach((element) => {
      if (element.errors.length > 0) {
        check = false;
      }
    });
    if (!check) {
      return;
    }
    try {
      // const dataHigh = `${values.instantaneousRainfallHigh},${values.hourlyRainfallHigh},${values.rainfallOnTheDayHigh},${values.totalRainfallHigh},${values.waterMainLevelHigh}`;
      const dataHigh = `5000,${values.waterMainLevelHigh},5000,${values.rainfallOnTheDayHigh},${values.instantaneousRainfallHigh},5000,${values.totalRainfallHigh},${values.hourlyRainfallHigh},5000`;

      const postSoftware = await telemetryService.postDeviceTele(
        idStation,
        SCOPE.SHARED_SCOPE,
        {
          Sethigh: dataHigh,
          Setlow: "-500,-500,-500,-500,-500,-500,-500,-500,-500",
        }
      );
      if (postSoftware.status === 200) {
        refetch();
        dispatch(showMsg("success", "Thành công"));
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };

  //! render
  const renderExtra = useCallback(() => {
    return (
      <CommonStyles.ButtonUI
        disabled={getStatusAction(valueInit, [
          Number(valueInstantaneousRainfallHigh),
          Number(valueThourlyRainfallHigh),
          Number(valueRainfallOnTheDayHigh),
          Number(valueTotalRainfallHigh),
          Number(valueWaterMainLevelHigh),
        ])}
        onClick={handleSubmit}
        size="small"
      >
        Hoàn thành
      </CommonStyles.ButtonUI>
    );
  }, [isLoading, initialValues]);
  return (
    <CommonStyles.SubCard
      title="Cài đặt ngưỡng cảnh báo thiết bị"
      extra={renderExtra()}
    >
      <Form
        form={form}
        name="deviceLMMN"
        initialValues={initialValues}
        layout="vertical"
      >
        <div className="md:grid md:grid-cols-2 gap-3 items-center">
          <CommonStyles.FormFieldUI
            name={"instantaneousRainfallHigh"}
            label="Lượng mưa tức thời (mm)"
            rules={[
              {
                required: true,
                message: "Lượng mưa tức thời không được trống!",
              },
              {
                pattern: Regex.number,
                message: "Giá trị cần là số!",
              },
            ]}
            component={
              <CommonStyles.InputUI
                maxLength={50}
                className="h-9 border !border-s-line active:!border-main-color"
              />
            }
          />
          <CommonStyles.FormFieldUI
            name={"hourlyRainfallHigh"}
            label="Lượng mưa theo giờ (mm)"
            rules={[
              {
                required: true,
                message: "Lượng mưa theo giờ không được trống!",
              },
              {
                pattern: Regex.number,
                message: "Giá trị cần là số!",
              },
            ]}
            component={
              <CommonStyles.InputUI
                maxLength={50}
                className="h-9 border !border-s-line active:!border-main-color"
              />
            }
          />
          <CommonStyles.FormFieldUI
            name={"rainfallOnTheDayHigh"}
            label="Lượng mưa trong ngày (mm)"
            rules={[
              {
                required: true,
                message: "Lượng mưa trong ngày không được trống!",
              },
              {
                pattern: Regex.number,
                message: "Giá trị cần là số!",
              },
            ]}
            component={
              <CommonStyles.InputUI
                maxLength={50}
                className="h-9 border !border-s-line active:!border-main-color"
              />
            }
          />
          <CommonStyles.FormFieldUI
            name={"totalRainfallHigh"}
            label="Tổng lượng mưa (mm)"
            rules={[
              {
                required: true,
                message: "Tổng lượng mưa không được trống!",
              },
              {
                pattern: Regex.number,
                message: "Giá trị cần là số!",
              },
            ]}
            component={
              <CommonStyles.InputUI
                maxLength={50}
                className="h-9 border !border-s-line active:!border-main-color"
              />
            }
          />

          <CommonStyles.FormFieldUI
            name={"waterMainLevelHigh"}
            label="Mực nước (cm)"
            className="col-span-2"
            rules={[
              {
                required: true,
                message: "Mực nước không được trống!",
              },
              {
                pattern: Regex.number,
                message: "Giá trị cần là số!",
              },
            ]}
            component={
              <CommonStyles.InputUI
                maxLength={50}
                className="h-9 border !border-s-line active:!border-main-color"
              />
            }
          />
        </div>

        {/* <CommonStyles.ButtonUI className="col-span-2 w-full" action="submit">
          Cập nhật
        </CommonStyles.ButtonUI> */}
      </Form>
    </CommonStyles.SubCard>
  );
}
