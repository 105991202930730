import CDRCStation from "../../components/station/CDRC";
import LMMNStation from "../../components/station/LMMN";
import { CommonStyles } from "../../components/ui";
import baseRouter from "../../router/baseRouter";

const DemoStation = () => {
  //! state
  //! function
  //! effect
  //! render

  return (
    <div className="flex flex-col gap-5">
      <CommonStyles.TitleCardUI
        title="Danh sách trạm đo"
        subTitle={[
          {
            title: "Danh sách trạm đo",
            link: baseRouter.station,
          },
        ]}
      />
      {/* <CDRCStation navigateRoute={"stations"} /> */}
      <LMMNStation navigateRoute={"stations"} />
    </div>
  );
};

export default DemoStation;
