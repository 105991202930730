import { Form, FormRule, FormItemProps } from "antd";
import React, { ReactNode } from "react";

interface IProps extends FormItemProps {
  name: string;
  label?: string | ReactNode;
  component: ReactNode;
  hasFeedback?: boolean;
  rules?: FormRule[];
  dependencies?: any;
}
export default function FormFieldUI(props: IProps) {
  //! state
  const { name, label, component, hasFeedback, rules, dependencies, ...res } =
    props;
  //! function
  //! render
  return (
    <Form.Item
      name={name}
      hasFeedback={hasFeedback}
      label={label}
      rules={rules}
      dependencies={dependencies}
      {...res}
    >
      {component}
    </Form.Item>
  );
}
