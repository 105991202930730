import { useTranslation } from "react-i18next";
import CheckBoxUI from "../ui/checkbox/Checkbox";
import ButtonUI from "../ui/button/Button";
import useToggle from "../../hooks/useToggle";
import { CommonStyles } from "../ui";
import ForgotPassword from "../forgotPassword";

const FormExtra = (props: any) => {
  const { t } = useTranslation();
  const { open, shouldRender, toggle } = useToggle();
  return (
    <div className="flex items-center justify-between ">
      <div className="flex items-center">
        {/* <CheckBoxUI>{t('Remember me')}</CheckBoxUI> */}
      </div>
      {/* 
      <div className="text-sm">
        <ButtonUI
          type="text"
          onClick={toggle}
          className="text-main-color hover:!text-main-color/90  hover:!bg-white"
        >
          {t("Forgot your password?")}
        </ButtonUI>
      </div>
      {shouldRender && (
        <CommonStyles.ModalUI
          title="Lấy lại mật khẩu"
          hiddenAction
          toggle={toggle}
          open={open}
          className="!w-[500px] "
        >
          <ForgotPassword toggle={toggle} />
        </CommonStyles.ModalUI>
      )} */}
    </div>
  );
};
export default FormExtra;
