import _ from "lodash";
import { TMsg } from "../interface";
import { handleToast } from "../store/Slices/ToastSlice";
import { Roles, optionsRole } from "../constants/Index";
import moment from "moment";
import { IDateFilter } from "../interface/IDateFilter";

export const getErrorMsg = (error: any) => {
  if (error?.response?.data?.error?.message) {
    return error.response.data.error.message;
  }
  if (error?.response?.data?.[0]) {
    return error?.response?.data?.[0];
  }
  if (error?.response?.data?.message) {
    return error?.response?.data?.message;
  }

  if (error?.response?.data?.detail) {
    return error?.response?.data?.detail;
  }

  return `Something wrong!`;
};

export const showMsg = (type: TMsg, msg: string) => {
  return (dispatch: any) => {
    dispatch(
      handleToast({
        type,
        content: msg,
      })
    );
  };
};

export const parseQueryString = (query: Object) => {
  return Object.entries(query)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
};

export const limitTextString = (text: string, limit: number) => {
  return _.truncate(text, {
    length: limit,
    separator: " ",
  });
};

export const checkRole = (role: string) => {
  const roleFind = optionsRole.find((item) => item.value === role);
  return roleFind?.label ?? "";
};

export const getTimeStampHour = (
  timeNumber: number,
  type: "end" | "start",
  typeFilter: IDateFilter
) => {
  let typeOf = typeFilter.type;

  if (typeFilter.type === "month") {
    typeOf = "date";
  }
  let time = "";
  if (type === "start") {
    time = moment.utc(timeNumber).startOf(typeOf).format("YYYY-MM-DD HH:mm:ss");
  }
  if (type === "end") {
    //! 57 minutes because dataloger to 59 minutes reset data rain
    time = moment
      .utc(timeNumber)
      .endOf(typeOf)
      .subtract("1", "minutes")
      .format("YYYY-MM-DD HH:mm:ss");
  }

  return moment.utc(time, "YYYY-MM-DD HH:mm:ss").valueOf();
};

export const getCreatedTime = (createdTime: number | string) => {
  return moment(createdTime).add(7, "hours").format("DD-MM-YYYY");
};

export const getStatusAction = (valueBefore: any[], value: any[]) => {
  let status = true;
  if (valueBefore?.length !== value?.length) {
    return false;
  }
  for (let index = 0; index < valueBefore?.length; index++) {
    if (valueBefore[index] !== value[index]) {
      status = false;
    }
  }
  return status;
};

export const functionRole = (userRole: string) => {
  if (userRole === Roles.ADMIN) {
    return 1;
  }
  if (userRole === Roles.CONFIG) {
    return 2;
  }
  if (userRole === Roles.CUSTOMER) {
    return 3;
  }
};

export const PluginTextCenterChart = (id: string, valueCenter: string) => {
  return valueCenter
    ? {
        id: id,
        beforeDatasetsDraw: function (chart: any, args: any, options: any) {
          const ctx = chart.ctx;
          ctx.save();
          ctx.textAlign = "center";
          ctx.fillStyle = "grey";
          ctx.font = "bold 30px Inter";
          ctx.fillText(`${valueCenter}`, chart.width / 2, chart.height / 2);
          ctx.restore();
        },
      }
    : null;
};

export const helpCalculateForDemoMonth1112 = (
  idStation: string,
  value?: number
) => {
  //! 1a664f30-bed8-11ee-9c13-f963bb896e46   --- xom thuong
  if (
    idStation === "1a664f30-bed8-11ee-9c13-f963bb896e46" &&
    value &&
    value > 50
  ) {
    return 39;
  }

  //! 1c2adf10-c037-11ee-9c13-f963bb896e46   --- ma mu
  if (
    idStation === "1c2adf10-c037-11ee-9c13-f963bb896e46" &&
    ((value && value < 0) || (value && value > 50))
  ) {
    return 10;
  }
  //! eb1c8440-c02d-11ee-9c13-f963bb896e46   --- suoi nanh
  if (
    idStation === "eb1c8440-c02d-11ee-9c13-f963bb896e46" &&
    value &&
    ((value && value < 0) || (value && value > 50))
  ) {
    return 10;
  }
  //! bee10c90-bed7-11ee-9c13-f963bb896e46 ---! Thanh Mai
  if (
    idStation === "bee10c90-bed7-11ee-9c13-f963bb896e46" &&
    value &&
    ((value && value < 0) || (value && value > 110))
  ) {
    return 90;
  }
  return value;
};
