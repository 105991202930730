import moment from "moment";
import { CommonStyles } from "../../../ui";
import { IDataTime } from "../../../../interface";
import telemetryService from "../../../../modules/telemetry/telemetry.service";
import { IDevice } from "../../../../modules/device/device.interface";
import { parseQueryString } from "../../../../helpers";
import { getDataFilterDate } from "../../../../helpers/filterDate";
import { useEffect, useState } from "react";
import _ from "lodash";
import { BellFilled, BellOutlined } from "@ant-design/icons";
import useGetAttributeKey from "../../../../modules/telemetry/hooks/useGetAttributeKey";
import { ENTITY_TYPE } from "../../../../constants/Index";
import useToggle from "../../../../hooks/useToggle";
import RingAlarm from "./RingAlarm";
interface IProps {
  detailDevice: IDevice;
}
const ReportTime = (props: IProps) => {
  //! state
  const { detailDevice } = props;
  const [dataTime, setDataTime] = useState<IDataTime[]>();
  const [reportRain, setReportRain] = useState({
    h24: 0,
    h12: 0,
    h1: 0,
  });
  const keys = [
    "waterMainLevelThresholdHigh",
    "rainfallDayThresholdHigh", //! 1h
    "instantaneousRainfallThresholdHigh", //! 24h
    "totalRainfallThresholdHigh", //! 1d from 0h
    "hourlyRainfallThresholdHigh", //! 12h
  ];

  const { data, isLoading } = useGetAttributeKey(
    ENTITY_TYPE,
    detailDevice.id.id,
    true,
    parseQueryString({
      keys: keys.join(","),
    })
  );
  const [dataAlarm, setDataAlarm] = useState<{
    max: number | string | any;
    data: number;
  }>();

  const { open, shouldRender, toggle } = useToggle();
  //! function
  const hours24 = data?.data.find(
    (item) => item.key === "instantaneousRainfallThresholdHigh"
  );
  const hours12 = data?.data.find(
    (item) => item.key === "hourlyRainfallThresholdHigh"
  );
  const hours1 = data?.data.find(
    (item) => item.key === "rainfallDayThresholdHigh"
  );

  const dataReport = async () => {
    let dataElement: IDataTime[] = [];

    const increaseTime = 3600000; // 1h
    const endTime = moment().valueOf(); // time current
    let startTime = endTime - 86400000; // satrtTime =  current -  last 24h
    while (startTime <= endTime) {
      const start = moment.utc(startTime).startOf("hour").valueOf(); //ex 3:00
      const end = moment
        .utc(startTime)
        .endOf("hour")
        .subtract(1, "minutes")
        .valueOf(); // ex: 3:59

      const detailStation = await telemetryService.getTimeSeries(
        detailDevice?.id?.entityType,
        detailDevice?.id?.id,
        parseQueryString({
          startTs: start,
          endTs: end,
          limit: 1,
          useStrictDataTypes: true,
          keys: "data",
          orderBy: "DESC",
        })
      );

      if (detailStation?.data?.data) {
        const dataOrder = getDataFilterDate(
          start,
          detailStation?.data?.data[0]?.value
        );
        dataElement.push(dataOrder);
      }

      startTime += increaseTime;
    }
    setDataTime(dataElement);
  };
  const hanldeShowDataAlarm = (type: "1h" | "12h" | "24h") => {
    if (type === "1h") {
      setDataAlarm({
        max: hours1?.value,
        data: reportRain.h1,
      });
    }
    if (type === "12h") {
      setDataAlarm({
        max: hours12?.value,
        data: reportRain.h12,
      });
    }
    if (type === "24h") {
      setDataAlarm({
        max: hours24?.value,
        data: reportRain.h24,
      });
    }
    toggle();
  };

  //! effect
  useEffect(() => {
    if (detailDevice) {
      dataReport();
    }
  }, [detailDevice]);
  useEffect(() => {
    if (dataTime && dataTime?.length > 0) {
      const rain24h = _.sumBy(dataTime, (item) => {
        return item.data["Hourly rainfall"].value;
      });

      const data12h = dataTime.slice(Math.max(dataTime.length - 12, 0));
      const rain12h = _.sumBy(data12h, (item) => {
        return item.data["Hourly rainfall"].value;
      });

      const data1h = dataTime?.slice(-1)[0];
      const rain1h = _.sumBy([data1h], (item) => {
        return item.data["Hourly rainfall"].value;
      });

      setReportRain({
        h1: Number(rain1h.toFixed(2)),
        h12: Number(rain12h.toFixed(2)),
        h24: Number(rain24h.toFixed(2)),
      });
    }
  }, [dataTime]);

  //! render
  return (
    <>
      <CommonStyles.CardResize
        title="Tổng lượng mưa khung giờ gần nhất tới hiện tại"
        className=" border border-gray-200 pb-0"
      >
        <div className="grid grid-cols-3 gap-4 pt-5">
          <CommonStyles.BoxInfo
            title="Trong 24h"
            value={reportRain.h24}
            unit={"mm"}
            actionNode={
              <div
                onClick={() => {
                  if (reportRain.h24 > hours24?.value) {
                    hanldeShowDataAlarm("24h");
                  }
                }}
                className={` ${
                  reportRain.h24 > hours24?.value
                    ? " bell-animation cursor-pointer"
                    : ""
                }`}
              >
                <BellFilled />
              </div>
            }
          />
          <CommonStyles.BoxInfo
            title="Trong 12h"
            value={reportRain.h12}
            unit={"mm"}
            actionNode={
              <div
                onClick={() => {
                  if (reportRain.h12 > hours12?.value) {
                    hanldeShowDataAlarm("12h");
                  }
                }}
                className={`cursor-pointer ${
                  reportRain.h12 > hours12?.value
                    ? " bell-animation cursor-pointer"
                    : ""
                }`}
              >
                <BellFilled />
              </div>
            }
          />
          <CommonStyles.BoxInfo
            title="Trong 1h"
            value={reportRain.h1}
            unit={"mm"}
            actionNode={
              <div
                onClick={() => {
                  if (reportRain.h1 > hours1?.value) {
                    hanldeShowDataAlarm("1h");
                  }
                }}
                className={` ${
                  reportRain.h1 > hours1?.value
                    ? " bell-animation cursor-pointer"
                    : ""
                }`}
              >
                <BellFilled />
              </div>
            }
          />
        </div>
      </CommonStyles.CardResize>
      {shouldRender && (
        <CommonStyles.ModalUI
          title="Thông tin cảnh báo vượt ngưỡng"
          open={open}
          toggle={toggle}
          hiddenAction
          maskClosable
        >
          <div className="text-lg mt-5">{`Tổng lượng mưa: ${
            dataAlarm?.data
          } > ${dataAlarm?.max} giá trị ngưỡng tại thời điểm ${moment().format(
            "DD/MM/YYY HH:mm"
          )}`}</div>
        </CommonStyles.ModalUI>
      )}
    </>
  );
};

export default ReportTime;
