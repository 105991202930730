import { Button, Popover, Tag } from "antd";
import CommonIcons from "../../../../assets/icons";
import useToggle from "../../../../hooks/useToggle";
import TooltipUI from "../../tooltip/TooltipUI";
import ButtonUI from "../Button";
import _ from "lodash";
import { CommonStyles } from "../..";
import InputUI from "../../input/Input";
import { useEffect, useState } from "react";

interface IItem {
  key: string;
  label: string;
  action?: undefined | any;
}
interface IProps {
  handleInfo?: any;
  handleEdit?: any;
  handleDelete?: any;
  moreItems?: IItem[];
  noToggle?: boolean;
}
export default function ButtonAction(props: IProps) {
  //! state
  const { handleDelete, handleEdit, handleInfo, moreItems, noToggle } = props;
  const { open, shouldRender, toggle } = useToggle();
  const {
    open: openConfirm,
    shouldRender: shouldRenderConfirm,
    toggle: toggleConfirm,
  } = useToggle();
  const [stateTypingDelete, setStateTypingDelete] = useState("");

  const items: IItem[] = [
    { key: "info", label: "Thông tin", action: handleInfo },
    { key: "edit", label: "Sửa", action: handleEdit },
    {
      key: "delete",
      label: "Xoá",
      action: () => {
        toggleConfirm();
      },
    },
  ];
  //! function
  const changeStateTypingDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setStateTypingDelete(value);
  };
  useEffect(() => {
    return () => {
      setStateTypingDelete("");
    };
  }, []);
  //! render

  const renderAction = () => {
    if (!handleInfo) {
      const indexInfo = items.findIndex((el) => el.key == "info");
      items.splice(indexInfo, 1);
    }
    if (!handleEdit) {
      const indexEdit = items.findIndex((el) => el.key == "edit");
      items.splice(indexEdit, 1);
    }

    if (!!moreItems) {
      Array.prototype.push.apply(items, moreItems);
    }
    if (!handleDelete) {
      const indexDelete = items.findIndex((el) => el.key == "delete");
      items.splice(indexDelete, 1);
    }
    return items?.map((item, index) => {
      return (
        <ButtonUI
          type="text"
          className={`text-left hover:!bg-transparent ${
            item.key === "delete"
              ? "text-red-500 hover:!text-red-500"
              : "hover:!text-main-color"
          }`}
          onClick={() => {
            item.action();
            toggle();
          }}
          key={item.key}
        >
          {item.label}
        </ButtonUI>
      );
    });
  };
  return (
    <div className="cursor-pointer relative">
      <Button type="text" onClick={toggle} className="hover:!bg-transparent">
        <CommonIcons.MoreOutlined className="text-base rotate-90" />
      </Button>

      {shouldRender && (
        <div className="absolute left-5">
          <Popover
            trigger={"click"}
            onOpenChange={toggle}
            placement="right"
            open={open}
            content={
              <div className="flex flex-col gap-2">{renderAction()}</div>
            }
          ></Popover>
        </div>
      )}

      {shouldRenderConfirm && (
        <CommonStyles.ModalUI
          type="error"
          toggle={toggleConfirm}
          open={openConfirm}
          onConfirm={() => {
            handleDelete();
            toggleConfirm();
          }}
          verifyDelete={stateTypingDelete === "delete"}
        >
          <InputUI
            value={stateTypingDelete}
            onChange={changeStateTypingDelete}
            placeholder="Nhập delete để xác nhận ..."
            className="w-60"
          />
        </CommonStyles.ModalUI>
      )}
    </div>
  );
}
