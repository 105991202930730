const api = process.env.REACT_APP_API_URL;
const apiSMS = process.env.REACT_APP_API_SMS;
export const API_LAYER_MAP = process.env.REACT_APP_LAYER_MAP;
export const urlsAPI = {
  auth: {
    login: `${api}/auth/login`,
    logout: `${api}/auth/logout`,
    token: `${api}/auth/token`,
    user: `${api}/auth/user`,
    changePassword: `${api}/auth/changePassword`,
    saveProfile: `${api}/user`,
    forgotPassword: `${api}/noauth/resetPasswordByEmail`,
    resetPassword: `${api}/noauth/resetPassword`,
    activateNoAuth: `${api}/noauth/activate`,
  },
  telemetry: {
    getTimeseries: (entityType: string, entityId: string, query?: string) => {
      return `${api}/plugins/telemetry/${entityType}/${entityId}/values/timeseries?${query}`;
    },
    getAttributeScope: (
      entityType: string,
      entityId: string,
      scope: string,
      query?: string
    ) => {
      return `${api}/plugins/telemetry/${entityType}/${entityId}/values/attributes/${scope}?${query}`;
    },
    getAttributeKey: (entityType: string, entityId: string, query?: string) => {
      return `${api}/plugins/telemetry/${entityType}/${entityId}/values/attributes?${query}`;
    },
    postDeviceTele: (device: string, scope: string) => {
      return `${api}/plugins/telemetry/${device}/${scope}`;
    },
  },
  device: {
    getById: (deviceId: string) => {
      return `${api}/device/${deviceId}`;
    },

    getCustomerDeviceInfos: (customerID: string, query?: string) => {
      return `${api}/customer/${customerID}/deviceInfos?${query}`;
    },
    getInfoById: `${api}/device/info`,
    tenantDeviceInfos: `${api}/tenant/deviceInfos`,
    createAndUpdate: `${api}/device`,
    typesDevice: `${api}/device/types`,
  },
  relation: {
    getInfo: `${api}/relations/info`,
  },
  customer: {
    getAll: `${api}/customers`,
    customer: `${api}/customer`,
  },
  user: {
    getCustomerUsers: (customerID: string, query: string) => {
      return `${api}/customer/${customerID}/users?${query}`;
    },
    getDetailUser: `${api}/user`,
  },
  notification: {
    list: `${api}/notifications`,
    detail: `${api}/notification`,
  },
  test: {
    test: `${api}/user/dashboards`,
  },
};

export const urlsAPISMS = {
  phone: {
    postPhone: `${apiSMS}/set_phone`,
    getPhone: `${apiSMS}/get_phone`,
    postTemplate: `${apiSMS}/sms_template`,
    getTemplate: `${apiSMS}/sms_template`,
  },
  sms: {
    send: `${apiSMS}/send_sms`,
  },
  groupPhones: {
    api: `${apiSMS}/group_phone`,
  },
};

// export const API_LOGIN = `${api}/auth/login`;
// export const API_LOGOUT = `${api}/auth`;
// export const API_TEST = `${api}/user/dashboards`;
