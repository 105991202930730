import { useTranslation } from "react-i18next";
import CommonIcons from "../../../assets/icons";
import { ENTITY_TYPE, SCOPE } from "../../../constants/Index";
import ResponseUI from "../../../constants/response";
import { getErrorMsg, parseQueryString, showMsg } from "../../../helpers";
import { useAppDispatch } from "../../../hooks/hooks";
import useToggle from "../../../hooks/useToggle";
import telemetryService from "../../../modules/telemetry/telemetry.service";
import { CommonStyles } from "../../ui";
import useGetAttributeKey from "../../../modules/telemetry/hooks/useGetAttributeKey";
interface IProps {
  idStation: string;
}
export default function Alarm(props: IProps) {
  //! station
  const { idStation } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { open, shouldRender, toggle } = useToggle();
  const { data, isLoading } = useGetAttributeKey(
    ENTITY_TYPE,
    idStation,
    true,
    parseQueryString({ keys: "TimeAlarm" })
  );
  const spaceTimeAlarm = 2000;
  //! function
  const handleAPI = async (status: string) => {
    const postAlarm = await telemetryService.postDeviceTele(
      idStation,
      SCOPE.SHARED_SCOPE,
      {
        out3: status,
      }
    );
    return postAlarm;
  };
  const toggleAlarm = () => {
    toggle();
  };
  const handleAlarm = async () => {
    try {
      const timeMilisecondAlarm = data && data.data[0]?.value * 1000;
      let count = timeMilisecondAlarm && timeMilisecondAlarm / spaceTimeAlarm;
      for (let index = 0; index < Number(count); index++) {
        setTimeout(async () => {
          const res = await handleAPI("on");
        }, index * spaceTimeAlarm);
      }

      dispatch(
        showMsg(
          "success",
          `Báo động đã bật và sẽ tự động tắt sau ${
            data && Number(Number(data.data[0]?.value) + 10)
          }s`
        )
      );

      setTimeout(async () => {
        const resOff = await handleAPI("off");
        if (resOff.status === 200) {
          dispatch(showMsg("success", "Báo động đã tắt"));
        }
      }, Number(Number(timeMilisecondAlarm) + 10000));
      toggle();

      return;

      const res = await handleAPI("on");
      if (res.status === 200) {
        toggle();
        dispatch(
          showMsg("success", "Báo động đã bật và sẽ tự động tắt sau 30s")
        );
        setTimeout(async () => {
          const resOff = await handleAPI("off");
          if (resOff.status === 200) {
            dispatch(showMsg("success", "Báo động đã tắt"));
          }
        }, 30000);
      }
    } catch (error) {
      dispatch(showMsg("error", t(`${getErrorMsg(error)}`)));
    }
  };
  //! render
  return (
    <>
      <CommonStyles.ButtonUI
        Icons={<CommonIcons.Bell style={{ color: "white" }} />}
        danger
        onClick={toggleAlarm}
        className={ResponseUI.button}
      >
        Báo động
      </CommonStyles.ButtonUI>
      {shouldRender && (
        <CommonStyles.ModalUI
          open={open}
          type="error"
          toggle={toggle}
          title="Bạn muốn bật báo động ?"
          onConfirm={handleAlarm}
          verifyDelete={true}
        />
      )}
    </>
  );
}
