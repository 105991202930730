import React, { useCallback, useState } from "react";
import CommonIcons from "../../../assets/icons";
import { Divider } from "antd";
import { CommonStyles } from "../../ui";
import useGetTemplates from "../../../modules/sms/hooks/useGetTemplates";
import { getErrorMsg, parseQueryString, showMsg } from "../../../helpers";
import { IDataTime } from "../../../interface";
import {
  LMMN,
  optionsContentSMSCDRC,
  optionsContentSMSLMMN,
} from "../../../constants/Index";
import smsService from "../../../modules/sms/sms.service";
import { IDevice } from "../../../modules/device/device.interface";
import { useAppDispatch } from "../../../hooks/hooks";
import useGetPhoneSms from "../../../modules/sms/hooks/useGetPhoneSms";
import _, { isEmpty } from "lodash";
import ResponseUI from "../../../constants/response";
import useGetGroupPhones from "../../../modules/sms/hooks/useGetGroupPhones";

interface IProps {
  toggle: () => void;
  toggleChoiceTemplate: () => void;
  detailTemplate: {
    id: string | null;
    length: number;
    dataSms: IDataTime | undefined;
    device: IDevice;
  };
}
export default function ModalMessage(props: IProps) {
  //! state
  const { toggle, toggleChoiceTemplate, detailTemplate } = props;
  const dispatch = useAppDispatch();
  const [content, setContent] = useState("");
  const { data } = useGetTemplates(parseQueryString({ id: detailTemplate.id }));
  const { data: dataPhone } = useGetPhoneSms(
    parseQueryString({
      station: detailTemplate.device.id.id,
    })
  );

  const { data: dataGroupPhone, isLoading: loadingGroupPhone } =
    useGetGroupPhones(parseQueryString({ status: true, limit: 10000 }));
  const [groupPhone, setGroupPhone] = useState("");
  //! function
  const renderMessage = () => {
    const option =
      data?.data.page_contents[0].station === LMMN
        ? optionsContentSMSLMMN
        : optionsContentSMSCDRC;
    let message = "";
    const lengthTags = data?.data.page_contents[0]?.tags?.length;
    data?.data.page_contents[0].tags.map((item, index) => {
      const findTag = option.find((el) => el.value === item);
      let character = ",";
      if (Number(lengthTags) - 1 === index) {
        character = ".";
      }
      const messageNoActive = ` ${findTag?.label}:---${character}`;
      switch (item) {
        //! code station
        case optionsContentSMSCDRC[0].value:
          message += `Trạm đo: ${detailTemplate.device.name.split("/")[0]}`;
          break;
        //!  station name
        case optionsContentSMSCDRC[1].value:
          message += ` ${detailTemplate.device.name.split("/")[1]}${character}`;
          break;
        //! date
        case optionsContentSMSCDRC[2].value:
          message += ` ${detailTemplate.dataSms?.date}/${detailTemplate.dataSms?.month}/${detailTemplate.dataSms?.year}`;
          break;
        //! hour
        case optionsContentSMSCDRC[3].value:
          message += ` ${detailTemplate.dataSms?.hour}:${detailTemplate.dataSms?.minute}${character}`;
          break;

        //! creackmetter
        case optionsContentSMSCDRC[4].value:
          if (detailTemplate.dataSms?.data.CrackMetter.status) {
            message += ` ${findTag?.label}:${detailTemplate.dataSms?.data.CrackMetter.value}(${detailTemplate.dataSms?.data.CrackMetter.unit})${character}`;
          } else {
            message += messageNoActive;
          }
          break;
        //! vector galmax
        case optionsContentSMSCDRC[5].value:
          if (detailTemplate.dataSms?.data["Vector Gal_Max"].status) {
            message += ` ${findTag?.label}:${detailTemplate.dataSms?.data["Vector Gal_Max"].value}(${detailTemplate.dataSms?.data["Vector Gal_Max"].unit})${character}`;
          } else {
            message += messageNoActive;
          }
          break;

        //! horly rain
        case optionsContentSMSLMMN[4].value:
          if (detailTemplate.dataSms?.data["Hourly rainfall"].status) {
            message += ` ${findTag?.label}:${detailTemplate.dataSms?.data["Hourly rainfall"].value}(${detailTemplate.dataSms?.data["Hourly rainfall"].unit})${character}`;
          } else {
            message += messageNoActive;
          }
          break;
        case optionsContentSMSLMMN[5].value:
          if (detailTemplate.dataSms?.data["Rainfall on the day"].status) {
            message += ` ${findTag?.label}:${detailTemplate.dataSms?.data["Rainfall on the day"].value}(${detailTemplate.dataSms?.data["Rainfall on the day"].unit})${character}`;
          } else {
            message += messageNoActive;
          }
          break;
        case optionsContentSMSLMMN[6].value:
          if (detailTemplate.dataSms?.data["Water Main Level"].status) {
            message += ` ${findTag?.label}:${detailTemplate.dataSms?.data["Water Main Level"].value}(${detailTemplate.dataSms?.data["Water Main Level"].unit})${character}`;
          } else {
            message += messageNoActive;
          }
          break;

        default:
          break;
      }
    });

    return message;
  };

  const onChangeTextArea = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setContent(e.target.value);
  };

  const getOptionPhones = useCallback(() => {
    return dataGroupPhone?.data.page_contents?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  }, [loadingGroupPhone]);

  const handleChangeGroup = (value: any) => {
    setGroupPhone(value);
  };
  const messageSend = `${renderMessage()} ${
    data?.data.page_contents[0].message
  }. ${content}`;

  const sendMessage = async () => {
    try {
      if (isEmpty(groupPhone)) {
        dispatch(showMsg("error", "Bạn chưa chọn nhóm gửi!"));
        return;
      }
      const result = await smsService.sendSMS(groupPhone, {
        message: messageSend,
      });
      if (result.status === 200) {
        dispatch(showMsg("success", "Thành công"));

        toggleChoiceTemplate();
      }
    } catch (error) {
      dispatch(showMsg("error", getErrorMsg(error)));
    }
  };
  const toolTipPhone = () => {
    if (dataGroupPhone && groupPhone) {
      return String(
        dataGroupPhone.data.page_contents.find((item) => item.id === groupPhone)
          ?.phone_number
      );
    } else {
      return "";
    }
  };
  //! render
  return (
    <div>
      <div className="bg-[#EBF3FE] flex items-start gap-2 p-2 rounded-md">
        <div>
          <CommonIcons.InfoCircleFilled className="text-[#1890FF]" />
        </div>
        <p className="text-[#1890FF]">
          Lưu ý: Tổng số kí tự trong 1 tin nhắn SMS (tính cả khoảng cách) là
          600. Bạn vui lòng xem lại nội dung trước khi ấn gửi.
        </p>
      </div>

      <div className="flex gap-5 md:gap-2  justify-end my-3">
        <div className="border-r px-2">
          Tổng tin nhắn: {Math.ceil(messageSend.length / 600)}
        </div>
        <div>
          Tổng kí tự:
          {messageSend.length}
        </div>
      </div>

      <div className="leading-7 md:leading-6">{renderMessage()}</div>
      <div>{data?.data.page_contents[0].message}</div>

      <Divider />
      <div className="flex justify-between mb-2">
        <label>
          Nhóm nhận thông báo <span className="text-red-500">*</span>{" "}
        </label>
        <CommonStyles.TooltipUI title={toolTipPhone()}>
          <div>
            Tổng số điện thoại:
            <span className="text-main-color">
              {!isEmpty(toolTipPhone()) ? toolTipPhone().split(",").length : 0}
            </span>
          </div>
        </CommonStyles.TooltipUI>
      </div>
      <CommonStyles.SelectUI
        className="min-h-9 mb-2"
        value={groupPhone}
        options={getOptionPhones() ?? []}
        onChange={handleChangeGroup}
      />

      <CommonStyles.TextAreaUI
        label="Ghi chú"
        onChange={onChangeTextArea}
        rows={5}
        placeholder="Nội dung thêm cho tin nhắn ..."
      />

      <div className="flex justify-end gap-3 mt-3">
        <CommonStyles.ButtonUI
          onClick={() => {
            if (detailTemplate.length > 1) {
              toggle();
            } else {
              toggleChoiceTemplate();
            }
          }}
          danger
          ghost
          className={`${ResponseUI.button} w-24`}
        >
          Hủy
        </CommonStyles.ButtonUI>
        <CommonStyles.ButtonUI
          onClick={sendMessage}
          className={`${ResponseUI.button} w-24`}
        >
          Gửi
        </CommonStyles.ButtonUI>
      </div>
    </div>
  );
}
